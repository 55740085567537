<template>
  <v-container>
    <h1 style="margin-bottom: 20px;">Termine</h1>

    <v-timeline dense>
      <v-timeline-item v-for="(event, i) in events" :key="i">
        <v-card>
          <v-card-title>{{event.name}}</v-card-title>
          <v-card-subtitle v-if="event.timevalue">{{event.timevalue}}</v-card-subtitle>
          <v-card-subtitle v-else>Beginn {{formatTime(event.date)}}</v-card-subtitle>
          <v-row>
            <v-col cols="12" :md="event.image ? 8 : 12">
              <v-card-text v-html="event.description" />
            </v-col>
            <v-col v-if="event.image" cols="12" :md="event.image ? 4 : 12">
              <v-img class="ma-4" :src="$ASSET_URL + event.image.id" max-height="400px" contain />
            </v-col>
          </v-row>
        </v-card>
      </v-timeline-item>
    </v-timeline>
    
  </v-container>
</template>

<script>

  export default {
    name: 'Events',
    components: {
    },
    data: () => ({
      events: null
    }),
    async created() {
      let temp = await this.$client.items("events").readMany({ fields: ['*.*'], sort: '-date'})
      this.events =  temp.data
    },
    methods: {
      formatTime: function(date) {
        let datetime = new Date(date)
        datetime = datetime.toLocaleString('de-DE')
        return datetime.substring(0,datetime.length - 3);
      }
    }
  }
</script>
